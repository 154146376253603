import { render, staticRenderFns } from "./tdTjBhkList.vue?vue&type=template&id=02f0abde&scoped=true&"
import script from "./tdTjBhkList.vue?vue&type=script&lang=js&"
export * from "./tdTjBhkList.vue?vue&type=script&lang=js&"
import style0 from "./tdTjBhkList.vue?vue&type=style&index=0&id=02f0abde&lang=less&"
import style1 from "./tdTjBhkList.vue?vue&type=style&index=1&id=02f0abde&scoped=less&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02f0abde",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Java\\healthy\\healthy-frame-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('02f0abde')) {
      api.createRecord('02f0abde', component.options)
    } else {
      api.reload('02f0abde', component.options)
    }
    module.hot.accept("./tdTjBhkList.vue?vue&type=template&id=02f0abde&scoped=true&", function () {
      api.rerender('02f0abde', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/healthy/tdTjBhk/tdTjBhkList.vue"
export default component.exports