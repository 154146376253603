var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tdTjBhkList" },
    [
      _c(
        "Card",
        [
          _c(
            "Row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.openSearch,
                  expression: "openSearch",
                },
              ],
            },
            [
              _c(
                "Form",
                {
                  ref: "searchForm",
                  attrs: {
                    model: _vm.searchForm,
                    inline: "",
                    "label-width": 70,
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handSearch.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "Form-item",
                    { attrs: { label: "企业名称" } },
                    [
                      _c(
                        "Select",
                        {
                          staticStyle: { width: "250px" },
                          attrs: { clearable: "", filterable: "" },
                          model: {
                            value: _vm.searchForm.crptName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "crptName", $$v)
                            },
                            expression: "searchForm.crptName",
                          },
                        },
                        _vm._l(_vm.companyList, function (item, i) {
                          return _c(
                            "Option",
                            { key: i, attrs: { value: item.name } },
                            [_vm._v(_vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "Form-item",
                    { attrs: { label: "体检日期" } },
                    [
                      _c("DatePicker", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          type: "daterange",
                          format: "yyyy-MM-dd",
                          clearable: "",
                          placeholder: "选择起始时间",
                        },
                        on: { "on-change": _vm.selectDateRange },
                        model: {
                          value: _vm.selectDate,
                          callback: function ($$v) {
                            _vm.selectDate = $$v
                          },
                          expression: "selectDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "FormItem",
                    { attrs: { label: "网传状态" } },
                    [
                      _c(
                        "Select",
                        {
                          staticStyle: { width: "250px" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.searchForm.flag,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "flag", $$v)
                            },
                            expression: "searchForm.flag",
                          },
                        },
                        _vm._l(_vm.flagRadio, function (item, i) {
                          return _c(
                            "Option",
                            { key: i, attrs: { value: item.value } },
                            [_vm._v(_vm._s(item.title) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormItem",
                    { attrs: { label: "是否复查" } },
                    [
                      _c(
                        "Select",
                        {
                          staticStyle: { width: "250px" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.searchForm.ifRhk,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "ifRhk", $$v)
                            },
                            expression: "searchForm.ifRhk",
                          },
                        },
                        _vm._l(_vm.ifRhkRadio, function (item, i) {
                          return _c(
                            "Option",
                            { key: i, attrs: { value: item.value } },
                            [_vm._v(_vm._s(item.title) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.drop
                    ? _c(
                        "Form-item",
                        { attrs: { label: "订单编号" } },
                        [
                          _c("Input", {
                            directives: [
                              {
                                name: "width",
                                rawName: "v-width",
                                value: 250,
                                expression: "250",
                              },
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "请输入订单编号",
                            },
                            model: {
                              value: _vm.searchForm.orderCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "orderCode", $$v)
                              },
                              expression: "searchForm.orderCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.drop ? _c("br") : _vm._e(),
                  _vm.drop
                    ? _c(
                        "Form-item",
                        { attrs: { label: "人员姓名" } },
                        [
                          _c("Input", {
                            directives: [
                              {
                                name: "width",
                                rawName: "v-width",
                                value: 250,
                                expression: "250",
                              },
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "请输入人员姓名",
                            },
                            model: {
                              value: _vm.searchForm.personName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "personName", $$v)
                              },
                              expression: "searchForm.personName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.drop
                    ? _c(
                        "Form-item",
                        { attrs: { label: "证件号码" } },
                        [
                          _c("Input", {
                            directives: [
                              {
                                name: "width",
                                rawName: "v-width",
                                value: 250,
                                expression: "250",
                              },
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "请输入证件号码",
                            },
                            model: {
                              value: _vm.searchForm.idc,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "idc", $$v)
                              },
                              expression: "searchForm.idc",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.drop
                    ? _c(
                        "FormItem",
                        { attrs: { label: "体检结论" } },
                        [
                          _c(
                            "Select",
                            {
                              directives: [
                                {
                                  name: "width",
                                  rawName: "v-width",
                                  value: 250,
                                  expression: "250",
                                },
                              ],
                              attrs: {
                                placeholder: "请选择",
                                clearable: "",
                                transfer: "",
                              },
                              model: {
                                value: _vm.searchForm.verdict,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "verdict", $$v)
                                },
                                expression: "searchForm.verdict",
                              },
                            },
                            _vm._l(_vm.conclusionData, function (item, i) {
                              return _c(
                                "Option",
                                { key: i, attrs: { value: item.value } },
                                [_vm._v(_vm._s(item.title) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.drop
                    ? _c(
                        "Form-item",
                        { attrs: { label: "主检建议" } },
                        [
                          _c("Input", {
                            directives: [
                              {
                                name: "width",
                                rawName: "v-width",
                                value: 250,
                                expression: "250",
                              },
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "请输入主检建议",
                            },
                            model: {
                              value: _vm.searchForm.mhkadv,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "mhkadv", $$v)
                              },
                              expression: "searchForm.mhkadv",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "Form-item",
                    {
                      staticClass: "br",
                      staticStyle: { "margin-left": "-2.78vw" },
                    },
                    [
                      _c(
                        "Button",
                        {
                          attrs: { type: "primary", icon: "ios-search" },
                          on: { click: _vm.handSearch },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c("Button", { on: { click: _vm.handReset } }, [
                        _vm._v("重置"),
                      ]),
                      _c(
                        "a",
                        {
                          staticClass: "drop-down",
                          on: { click: _vm.dropDown },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.dropDownContent) + " "),
                          _c("Icon", { attrs: { type: _vm.dropDownIcon } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "operation" },
            [
              _c(
                "Button",
                {
                  attrs: { type: "info", loading: _vm.syncLoading },
                  on: { click: _vm.syncData },
                },
                [
                  !_vm.syncLoading
                    ? _c("Icon", { attrs: { type: "ios-loading" } })
                    : _vm._e(),
                  _vm._v(" 数据同步 "),
                ],
                1
              ),
              _c(
                "Button",
                {
                  attrs: { type: "error", loading: _vm.syncLoading },
                  on: { click: _vm.syncDataError },
                },
                [
                  !_vm.syncLoading
                    ? _c("Icon", { attrs: { type: "ios-loading" } })
                    : _vm._e(),
                  _vm._v(" 错误数据同步 "),
                ],
                1
              ),
              _c(
                "Button",
                {
                  attrs: { type: "info", loading: _vm.syncLoading },
                  on: { click: _vm.syncDataReview },
                },
                [
                  !_vm.syncLoading
                    ? _c("Icon", { attrs: { type: "ios-loading" } })
                    : _vm._e(),
                  _vm._v(" 复查数据同步 "),
                ],
                1
              ),
              _c(
                "Button",
                {
                  attrs: { type: "error", loading: _vm.syncLoading },
                  on: { click: _vm.syncDataErrorReview },
                },
                [
                  !_vm.syncLoading
                    ? _c("Icon", { attrs: { type: "ios-loading" } })
                    : _vm._e(),
                  _vm._v(" 复查错误数据同步 "),
                ],
                1
              ),
              _c(
                "Button",
                {
                  attrs: { type: "success", loading: _vm.upLoading },
                  on: {
                    click: function ($event) {
                      return _vm.upData(2)
                    },
                  },
                },
                [
                  !_vm.upLoading
                    ? _c("Icon", { attrs: { type: "ios-loading" } })
                    : _vm._e(),
                  _vm._v(" 自定义上报 "),
                ],
                1
              ),
              _c(
                "Dropdown",
                { on: { "on-click": _vm.handDropdown } },
                [
                  _c(
                    "Button",
                    [
                      _vm._v(" 更多操作 "),
                      _c("Icon", { attrs: { type: "md-arrow-dropdown" } }),
                    ],
                    1
                  ),
                  _c(
                    "DropdownMenu",
                    { attrs: { slot: "list" }, slot: "list" },
                    [
                      _c(
                        "DropdownItem",
                        { attrs: { name: "refresh" } },
                        [
                          _c("Icon", { attrs: { type: "md-sync" } }),
                          _vm._v(" 刷新 "),
                        ],
                        1
                      ),
                      _c(
                        "DropdownItem",
                        { attrs: { name: "export" } },
                        [
                          _c("Icon", { attrs: { type: "md-arrow-down" } }),
                          _vm._v(" 导出本页数据 "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Button",
                {
                  attrs: { type: "dashed" },
                  on: {
                    click: function ($event) {
                      _vm.openSearch = !_vm.openSearch
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.openSearch ? "关闭搜索" : "开启搜索"))]
              ),
              _c(
                "Button",
                {
                  attrs: { type: "dashed" },
                  on: {
                    click: function ($event) {
                      _vm.openTip = !_vm.openTip
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.openTip ? "关闭提示" : "开启提示"))]
              ),
              _c(
                "div",
                { staticStyle: { float: "right" } },
                [
                  _c(
                    "Poptip",
                    {
                      attrs: {
                        transfer: "",
                        trigger: "click",
                        placement: "bottom-end",
                        title: "动态列",
                      },
                    },
                    [
                      _c("Button", { attrs: { icon: "md-list" } }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "relative",
                            "min-height": "5vh",
                          },
                          attrs: { slot: "content" },
                          slot: "content",
                        },
                        [
                          _c(
                            "Checkbox-group",
                            {
                              on: { "on-change": _vm.checkboxChange },
                              model: {
                                value: _vm.colOptions,
                                callback: function ($$v) {
                                  _vm.colOptions = $$v
                                },
                                expression: "colOptions",
                              },
                            },
                            _vm._l(_vm.colSelect, function (item, i) {
                              return _c(
                                "row",
                                { key: i },
                                [
                                  _c("checkbox", {
                                    staticStyle: {
                                      "margin-bottom": "5px",
                                      "font-weight": "700",
                                    },
                                    attrs: { label: item },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.openTip,
                  expression: "openTip",
                },
              ],
            },
            [
              _c("Alert", { attrs: { "show-icon": "" } }, [
                _vm._v(" 已选择 "),
                _c("span", { staticClass: "select-count" }, [
                  _vm._v(_vm._s(_vm.selectCount)),
                ]),
                _vm._v(" 项 "),
                _c(
                  "a",
                  {
                    staticClass: "select-clear",
                    on: { click: _vm.clearSelectAll },
                  },
                  [_vm._v("清空")]
                ),
                _c("span", { staticStyle: { float: "right" } }, [
                  _vm._v("点击右上角按钮配置动态列↑"),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Table", {
                ref: "table",
                attrs: {
                  loading: _vm.loading,
                  border: "",
                  columns: _vm.columns,
                  sortable: "custom",
                  data: _vm.data,
                  "max-height": _vm.tableMaxHeight,
                },
                on: {
                  "on-sort-change": _vm.changeSort,
                  "on-selection-change": _vm.showSelect,
                },
                scopedSlots: _vm._u([
                  {
                    key: "action",
                    fn: function ({ row, index }) {
                      return [
                        _c(
                          "Button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handEdit(row, index)
                              },
                            },
                          },
                          [
                            _c("Icon", { attrs: { type: "ios-eye-outline" } }),
                            _vm._v(" 编辑 "),
                          ],
                          1
                        ),
                        _c(
                          "Button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handLook(row, index)
                              },
                            },
                          },
                          [
                            _c("Icon", { attrs: { type: "ios-eye-outline" } }),
                            _vm._v(" 查看 "),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "Row",
            { staticClass: "page", attrs: { type: "flex", justify: "end" } },
            [
              _c("Page", {
                attrs: {
                  current: _vm.searchForm.pageNumber,
                  total: _vm.total,
                  "page-size": _vm.searchForm.pageSize,
                  "page-size-opts": [10, 20, 50],
                  size: "small",
                  "show-total": "",
                  "show-elevator": "",
                  "show-sizer": "",
                },
                on: {
                  "on-change": _vm.changePage,
                  "on-page-size-change": _vm.changePageSize,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("updateTdTjBhk", {
        attrs: { TdTjBhkId: _vm.TdTjBhkId, modalTitle: _vm.title },
        on: { handSearch: _vm.handSearch },
        model: {
          value: _vm.updateShow,
          callback: function ($$v) {
            _vm.updateShow = $$v
          },
          expression: "updateShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }